<template>
  
    <b-overlay :show="showOverlay" rounded="sm">
      <div v-if="logements.length>0">
          <form-wizard ref="logementForm"  title='' subtitle='' :nextButtonText="$t('data.batiment_form_wizard_suivant')" :backButtonText="$t('data.batiment_form_wizard_precedent')" :finishButtonText='$t("data.batiment_form_wizard_bouton_enregistrer")' aria-labelledby="demoModalLabel"
                        @on-complete="submitForm"
                        @on-loading="setLoading"
                        @on-validate="handleValidation"
                        shape="circle"
                        color="#e74c3c">          
            <tab-content :title="$t('data.batiment_form_wizard_tab_content_1_titre')" icon="fa fa-bullhorn" :before-change="validateFirst">
                <b-row>
                  <b-col>
                    <b-form-group
                      :label="$t('data.annonce_form_titre_annonce')"
                      :description="titreAnnonce"
                    >
                      <b-form-input
                        v-model="annonce.titre"
                        :placeholder="$t('data.annonce_form_titre_annonce_exemple')"
                        trim
                         :class="{'is-invalid': !requiredTitre}"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Nombre de mois à payer d'avance"
                      description=""
                      
                    >
                      <b-form-input
                        v-model="annonce.moisAPayer"
                        placeholder="Nombre de mois à payer d'avance"
                        trim
                        :value="annonce.moisAPayer"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="7">
                    <b-form-group :label="$t('data.annonce_form_label_description_annonce')">
                      <b-form-textarea
                        class="mb-2"
                        :placeholder="$t('data.annonce_form_label_description_annonce_exemple')"
                        rows="8"
                        v-model="annonce.description"
                        :class="{'is-invalid': !requiredDescription}"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                  <b-col cols="5">
                    <b-form-group :label="$t('data.annonce_form_label_selection_logement')">
                      <div style="height: 10.5em">
                        <b-form-select
                          v-model="annonce.idLogement"
                          :options="logements"
                          class="mb-1"
                          @input="setSlide"
                          value-field="idLogement"
                          text-field="refLogement"
                          disabled-field="notEnabled"
                          :class="{'is-invalid': !requiredLogement}"
                        ></b-form-select>
                        <b-carousel :interval="0" controls v-model="selected_index" ref="myCarousel">
                          <b-carousel-slide
                            style="height: 10.5em"
                            class="fluid w-100 responsive border-0"
                            v-for="(logement, i) in logements"
                            :key="i"
                            :img-src="empty(logement.photos) ? '/img/bgHousing.jpg' : logement.photos[0].image"
                          />
                        </b-carousel>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="caution libératoire"
                      description="somme à débourser en cas de réticence après engagement"
                    >
                      <b-form-input
                        v-model="annonce.caution"
                        placeholder="Caution"
                        trim
                        :value="annonce.caution"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col >
                    <label class="ml-2" for="exampleInputUsername1" style="width: 90%; padding-left: 3em;"><span class="ml-4" style="width:30%; font-size: 1.5em"> prix</span> 
                      <!-- <span style="width:70%" class="example-form-control" v-model="annonce.montant"></span> -->
                      <!-- <vue-numeric style="width:70%" class="example-form-control" required separator="," v-model="annonce.montant"></vue-numeric> -->
                      <b-form-input
                        v-model="annonce.montant"
                        placeholder="Montant"
                        trim
                        :value="annonce.montant"
                      ></b-form-input>
                    </label>
                  </b-col>
                </b-row>
                 <b-row>
                  <b-col>
                    <b-form-checkbox v-model="annonce.publish" switch>
                        <span class="fa-lg" v-if="annonce.publish">{{$t('data.annonce_form_publier_annonce')}}</span>
                        <span class="fa-lg" v-else>{{$t('data.annonce_form_validite_annonce')}}</span>
                    </b-form-checkbox>
                  </b-col>
                  <!--<b-col >
                    <label class="ml-2" for="exampleInputUsername1" style="width: 90%; padding-left: 3em;"><span class="ml-4" style="width:30%; font-size: 1.5em"> prix</span> <vue-numeric style="width:70%" class="example-form-control" required separator="," v-model="annonce.montant"></vue-numeric></label>
                    
                  </b-col>-->
                </b-row>

 
                <transition enter-active-class="animated zoomIn"  leave-active-class="animated fadeOut zoomOut">
                  <b-row v-if="!annonce.publish">
                    <b-col>
                      <b-form-group
                        :label="$t('data.annonce_form_validite_label_date_debut_annonce')"
                        :description="$t('data.annonce_form_validite_label_date_debut_description_annonce')"
                      >
                        <b-form-datepicker locale="fr" v-model="duree[0]" :min="$date().format()"></b-form-datepicker>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                      :label="$t('data.annonce_form_validite_label_date_fin_annonce')"
                      :description="$t('data.annonce_form_validite_label_date_fin_description_annonce')">
                        <b-form-datepicker locale="fr" v-model="duree[1]" :min="$date().add(1, 'day').format()"></b-form-datepicker>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </transition>

            </tab-content>
            <tab-content v-if="showTabPhotos" :title="$t('data.logement_wizard_tab_content_2_titre')" icon="fas fa-image" :before-change="validatePicturesStep">
                <div
                    id="my-strictly-unique-vue-upload-multiple-image"
                    style="display: flex; justify-content: center;"
                >
                    <vue-upload-multiple-image
                    @upload-success="uploadImageSuccess"
                    @before-remove="beforeRemove"
                    @edit-image="editImage"
                    :data-images="images"
                    :dragText="$t('data.logement_dragText')"
                    :browseText="$t('data.logement_browseText')"
                    idUpload="myIdUpload"
                    editUpload="myIdEdit"
                    ></vue-upload-multiple-image>
                </div>
            </tab-content>
            <tab-content title="autres informations" icon="fas fa-info-circle">
                    <b-row>
                      <b-col class="mr-1">
                        <b-row>
                          <span class="font-weight-bold">Sources en eau disponilbes</span>
                          <div class="mt-1 w-100 d-flex justify-content-between flex-wrap">
                                <b-list-group-item class="w-50" v-for="(item, i) in eau" :key="i">
                                    <label class="d-flex justify-content-between align-items-center w-100">
                                        <div style="flex: 1" class="ml-2">
                                            <h6 class="mt-0 text-muted"><span> {{ item.ravitaillement }} </span></h6>
                                        </div>
                                        <input :disabled="submitted" type="checkbox" v-model="item.selected" />
                                    </label>
                                </b-list-group-item>
                            </div>
                        </b-row>
                        <hr />
                        <b-row>
                          <div style="height: 30em; overflow-y: auto; overflow-x: hidden;">
                             <b-card bg-variant="light">
                                <div style="size: small">
                                    <h6 class="text-capitalize font-weight-bold fs-3 mt-2 mb-1">les conditions particulières de votre logement</h6>
                                  <div :id="repeaterId" style="position: relative">
                                          <div data-repeater-list="group">
                                              <b-row data-repeater-item class="mt-1 mb-1 ml-2">
                                                  <b-col cols="8"  class="p-0">
                                                      <b-form-input class="form-control" placeholder="animaux autorisés" name="libelle" v-model="libelle"></b-form-input>
                                                  </b-col> 
                                                  <b-col cols="2" class="ml-2 p-0 ">
                                                      <b-form-group label="">
                                                          <b-button data-repeater-delete variant="outline-danger"><i class="ik ik-trash-2"></i></b-button>
                                                      </b-form-group>
                                                  </b-col>
                                            
                                              </b-row>   
                                          </div>
                                          <b-col cols="3" class="m-0 p-0" style="position : absolute; top: 2px; left: 90%">
                                              <b-button data-repeater-create class="btn btn-success btn-icon"><i class="ik ik-plus"></i></b-button>
                                          </b-col>   
                                  </div>
                                </div>
                          </b-card>
                          </div>
                         
                        </b-row>
                      </b-col>
                      <b-col>
                          <b-row>
                            <!--<h4>Les points forts de votre logement</h4>-->
                            <b-form-group class="font-weight-bold" label="Sélectionnez les points forts de votre logement">
                              <v-select label="libelle" multiple :options="pointsForts" v-model="annonce.avantages" class="w-100" :reduce="pointFort => pointFort.libelle">
                                  <template #option="{ libelle}">
                                      {{ libelle }} 
                                      <br />
                                      <!--<span class="small text-muted">{{ cite.nomCite }}</span>-->
                                  </template>
                              </v-select>
                            </b-form-group>
                          </b-row>
                          <hr />
                          <b-row>
                            <span class="font-weight-bold">cochez les lieux à proximité de votre logement</span>
                            <div class="mt-1 w-100 d-flex justify-content-between flex-wrap">
                                  <b-list-group-item class="w-50" v-for="(item, i) in lieuxProximite" :key="i">
                                      <label class="d-flex justify-content-between align-items-center w-100">
                                          <div style="flex: 1" class="ml-2">
                                              <h6 class="mt-0 text-muted"><i :class="item.icon"></i><span> {{ item.lieu }} </span></h6>
                                          </div>
                                          <input :disabled="submitted" type="checkbox" v-model="item.selected" />
                                      </label>
                                  </b-list-group-item>
                              </div>
                          </b-row>
                          <b-row></b-row>
                      </b-col>
                    </b-row>
            </tab-content>    
            <div class="leloader" v-if="loadingWizard"></div>
        </form-wizard>
      </div>
      <div v-else>
        <b-row>
          <div class="text-center">
            <i class="fa fa-exclamation-triangle fa-3x"></i> <br>
            <span class="h4 d-inline-flex ml-2">{{$t('data.annonce_form_pas_de_logement')}}</span>
            <br>
            <b-button size="lg" class="my-2" variant="outline-info" @click.prevent="createLogement">{{$t('data.annonce_form_creer_logement')}}</b-button>
            <p>{{$t('data.annonce_form_explication')}}</p>
          </div>
        </b-row>
      </div>
    </b-overlay>
</template>
<script>
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import VueUploadMultipleImage from "vue-upload-multiple-image";
import notif from "@/plugins/notif.js";
const php = require("phpjs");
export default {
  name: "annonce-form",
  data: () => ({
    selectedPoints:[],
      check:true,
      requiredTitre:true,
      nbCaractTitre:true,
      requiredDescription:true,
      nbCaractDescription:true,
      requiredLogement:true,
      requiredNbmois:true,
      requiredPrix:true,

      titreAnnonce:'donnez un titre intuitif à votre annonce',
      libelle:'',

    duree: [null, null],
    selected_index: -1,
    selected_value: "",
    showTabPhotos:false,
    images: [],
    photos:[],
    annonce: {
      titre: "",
      description: "",
      moisAPayer: "",
      idLogement: "",
      montant:0,
      caution:'',
      duree: "",
      ravitaillement:{
        eau:[],
        energie:[]
      },
      publish: true,
      photos:[],
      lieuxProximite:[],
      avantages:[],
      conditions:[]
    },
    idAnnonce:'',
     loadingWizard: false,
    showOverlay: false,
    sendForm: false,
    logements: [],
    commande:false,
    submitted:false,
    eau:[
        {
            ravitaillement : 'Robinet',
        },
        {
            ravitaillement : 'Puits',

        },
        {
            ravitaillement : 'Forage',
        },
        {
            ravitaillement : 'Rivière/fleuve',
        },
    ],
    lieuxProximite:[
      {
          lieu : 'Ecole primaire',
          icon:'fas fa-school',
      },
      {
          lieu : 'Ecole secondaire',
          icon:'fas fa-user-graduate',
      },
      {
          lieu : 'Université',
          icon:'fas fa-university',
      },
      {
          lieu : 'Hopital',
          icon:'fas fa-hospital',
          id:'lieu4',
      },
      {
          lieu : 'Eglise',
          icon:'fas fa-church',
      },
      {
          lieu : 'Marché',
          icon:'fas fa-store',
      },
      {
          lieu : 'Super-marché',
          icon:'fa fa-shopping-cart',
      },
      {
          lieu : 'Pharmacie',
          icon:'fas fa-clinic-medical',
      },
      {
          lieu : 'Mosqué',
          icon:'fas fa-mosque',
      },
            ],
    caracteristiques: [
                    {
                        libelle: "chambre",
                        valeur: 1
                    },
                    {
                        libelle: "salon",
                        valeur: 2
                    },
                    {
                        libelle: "cuisine",
                        valeur: 3
                    },
                    {
                        libelle: "douche",
                        valeur: 4
                    },
                    {
                        libelle: "parking",
                        valeur: 5
                    }
      ],
    pointsForts:[
        {
            libelle : 'Piscine',
            icon:'bx bx-water',
            id:'point1',
        },
        {
            libelle : 'Balcon',
            icon:'deuxieme',
            id:'point2',
        },
        {
            libelle : 'Jardin',
            icon:'troisième',
            id:'point3',
        },
        {
            libelle : 'Parking',
            icon:'car-garage',
            id:'point4',
        },
        {
            libelle : 'gardien',
            icon:'quatrième',
            id:'point5',
        },
        {
            libelle : 'logement cloisonné',
            icon:'quatrième',
            id:'point6',
        },
        {
            libelle : 'pas de voisinage',
            icon:'low-vision',
            id:'point7',
        },
        {
            libelle : 'rien',
            icon:'quatrième',
            id:'point8',
        },
    ]
  }),
  components:{
    FormWizard,
    TabContent,
    VueUploadMultipleImage
  },
  computed:{
    repeaterId() {
        return `repeat-added-annonce-form-${php.empty(this.annonce) ? php.uniqid() : this.annonce.idAnnonce}`
    },
    selectedWaterSouces() {
      return this.eau.filter(elt => elt.selected).map(elt => elt.ravitaillement)
    },
    selectedLieuxProximite() {
      return this.lieuxProximite.filter(elt => elt.selected).map(elt => elt.lieu)
    }
  },
  watch: {
    selected_index(value) {
      this.annonce.idLogement = this.logements[value].idLogement;
      this.annonce.montant = this.logements[value].prixMax;

      console.log('logement',this.logements[value])  
    }
  },
  props: {
      action: {type: String, default: 'add'},
      editAnnonce: {type: Object, default: null}
    },

  methods: {

    submitForm(){
        this.annonce.photos=this.photos;
        this.annonce.lieuxProximite=this.selectedLieuxProximite;
        this.annonce.ravitaillement.eau=this.selectedWaterSouces;
        this.annonce.conditions = $(`#${this.repeaterId}`).repeaterVal().group
        console.log('annonce finale',this.annonce)

        


        if(this.action=='add'){
            this.annonce.duree = this.duree.join(",");
            axios
            .post("annonces", this.annonce)
            .then(response => {
              this.resetModal();
              notif.success(response.message);
              this.$emit("annonceAdded");
              this.submitted = false ;
              return App.notifySuccess(response.message);
            })
            .catch(error => {
              this.submitted = false ;
              return App.alertError(error.message);
            });
            setTimeout(() => {
                this.showOverlay = false;
            }, 500);


          }

            if (this.action == 'edit' && !php.empty(this.annonce)) {
                axios.put(`annonces/${this.idAnnonce}`, this.annonce).then(response => {
                    //this.$emit('annonceUpdated', {response, idAnnonce: this.idAnnonce})
                    this.$emit('annonceUpdated')
                    this.submitted = false
                    this.showOverlay = false;
                    return App.notifySuccess(response.message)
                }).catch(error => {
                    this.submitted = false
                    this.showOverlay = false;
                    return App.notifyError(error.message)
                })
            }
            
    },
    validateFirst(){
      return new Promise((resolve, reject) => {
          if(!this.annonce.titre || this.annonce.titre.length<20 || !this.annonce.moisAPayer || this.annonce.titre.description<40 || !this.annonce.description || !this.annonce.idLogement|| !this.annonce.montant){
                    this.check=false
                    if(!this.annonce.titre){ this.requiredTitre=false; this.titreAnnonce="Bien vouloir renseigner ce champ"}
                    else{this.requiredTitre=true;}
                    if (this.annonce.titre.length <20) {
                      this.nbCaractTitre=false;
                      App.error('Le titre doit contenir au moins 20 caractères')
                    }
                    else{this.nbCaractTitre=true}
                    if(!this.annonce.moisAPayer){ this.requiredNbmois=false;}
                    else{this.requiredNbmois=true;}
                    if(!this.annonce.description){ this.requiredDescription=false;}
                    else{this.requiredDescription=true;}
                     if (this.annonce.description.length<40) {
                       this.nbCaractDescription=false;
                      App.error('La description doit contenir au moins 40 caractères')
                    }
                    else{this.nbCaractDescription=true;}
                    if(!this.annonce.idLogement){ this.requiredLogement=false;}
                    else{this.requiredLogement=true;}
                    if(!this.annonce.montant){ this.requiredPrix=false;}
                    else{this.requiredPrix=true;}

          }else{
                this.check=true
          }
      setTimeout(() => {
          resolve(this.check)
      }, 1000)
      })
    },
    validatePicturesStep(){
        return new Promise((resolve, reject) => {
          if(this.photos.length<5){
              this.check=false
              App.error('Bien vouloir ajouter au moins 5 photos de votre logement')
          }
          else{
              this.check=true
          }
      setTimeout(() => {
          resolve(this.check)
      }, 1000)
      })
    },
     makeRepeater() {
        setTimeout(() => {
            $(`#${this.repeaterId}`).repeater({ isFirstItemUndeletable: true  })
        }, 500);
    },
    setLoading: function(value) {
            this.loadingWizard = value
    },
    handleValidation: function(isValid, tabIndex){
        console.log('Tab: '+tabIndex+ ' valid: '+isValid)
        this.indexForm=this.indexForm+1;
        console.log('indexFom: ',this.indexForm)
    },
    validateAsync:function() {
        return new Promise((resolve, reject) => {
            if(this.idBatiment!=null){
              this.disabled=true;
            }
            
        setTimeout(() => {
            resolve(this.check)
        }, 1000)
        })
    },
    uploadImageSuccess(formData, index, fileList) {
        this.photos=[];
        console.log("taille Totale", fileList.length-1,"formaData",formData);
        for (let i = 0; i < fileList.length; i++) {
            //console.log("essai",fileList[i].path)
            this.photos.push(fileList[i].path);
        }
          console.log("photosTaille", this.photos.length);
    },
    beforeRemove(index, done, fileList) {
        console.log("index", index, fileList);
        var r = confirm("voulez vous supprimer cette image");
        if (r == true) {
            done();
        } else {
            console.log("erreur");
        }
    },
    editImage(formData, index, fileList) {
        console.log("edit data", fileList);
    },
    /**
     * Set l'image du carousel lorsqu'on change le logement
     */
    setSlide(value) {
      for (let index = 0; index < this.logements.length; index++) {
        if (this.logements[index].idLogement == value) {
          console.log('taille photos',this.logements[index].photos.length);
          this.$refs.myCarousel.setSlide(index);
          if(this.logements[index].photos.length<=1){
            this.showTabPhotos=true
          }
          else{
            this.showTabPhotos=false
          }
          break;
        }
      }
    },
    empty(val) {
        return php.empty(val)
    },

    resetModal() {
      this.annonce = {
         titre: "",
          description: "",
          moisAPayer: "",
          idLogement: "",
          montant:0,
          caution:'',
          duree: "",
          ravitaillement:{
            eau:[],
            energie:[]
          },
          publish: true,
          photos:[],
          lieuxProximite:[],
          waterSources:[],
          avantages:[],
          conditions:[]
      };
    },

    //validation formulaire d'ajout/modification d'una annonce
    submitModal(bvModalEvt) {
        this.annonce.duree = this.duree.join(",");
      bvModalEvt.preventDefault();
      if (php.empty(this.annonce.titre) || php.empty(this.annonce.description)|| php.empty(this.annonce.duree)) {
                return App.error('Un titre, une description et une durée sont obligatoires')
      }
      if (this.annonce.titre.length <20) {
        return App.error('Le titre doit contenir au moins 20 caractères')
      }
      if (this.annonce.description.length<40) {
        return App.error('La description doit contenir au moins 40 caractères')
      }
      this.submitted = true ;
      this.showOverlay = true;
      if (this.action == "add") {
        console.log("annonce",this.annonce)
        
          axios
            .post("annonces", this.annonce)
            .then(response => {
              notif.success(response.message);
              this.$emit("annonceAdded");
              this.submitted = false ;
              return App.notifySuccess(response.message);
            })
            .catch(error => {
              this.submitted = false ;
              return App.alertError(error.message);
            });
            setTimeout(() => {
                this.showOverlay = false;
            }, 500);
            
      }
      if (this.action == 'edit' && !php.empty(this.annonce)) {
            axios.put(`annonces/${this.idAnnonce}`, this.annonce).then(response => {
                //this.$emit('annonceUpdated', {response, idAnnonce: this.idAnnonce})
                this.$emit('annonceUpdated')
                this.submitted = false
                this.showOverlay = false;
                return App.notifySuccess(response.message)
            }).catch(error => {
                this.submitted = false
                this.showOverlay = false;
                return App.notifyError(error.message)
            })
        }
    },

     //recupération de la liste des logements
    async getHousing() {
        this.showOverlay = true;
        if(storage.get('logements')!= null && storage.get('logements').length>0){
          this.logements=storage.get('logements')
        }else{
          this.logements = await axios.get("logements").then(response => response.result || []);
          storage.set('logements',this.logements)
        }
   
           console.log('entrée 2', 'editAnnonce', this.editAnnonce)
        this.showOverlay = false;
    },
    /**emission évènement pour fermer le formulaire
     * de création d'une annonce et basculer à la création préalable d'un logement
     */
    createLogement(){
      this.$emit('createLogementFirst') 
    }
  },
  async mounted() {
    if (!php.empty(this.annonce)) {
        this.makeRepeater()
    }
    await this.getHousing();
         if (this.editAnnonce!=null) {

                this.annonce.titre = this.editAnnonce.titreAnnonce
                this.annonce.description = this.editAnnonce.descAnnonce
                this.annonce.tags  = this.editAnnonce.tags
                this.annonce.idLogement = this.editAnnonce.idLogement
                this.setSlide(this.annonce.idLogement)
                this.idAnnonce=this.editAnnonce.idAnnonce

        }
  },
  submitForm(){
    console.log('entrée terminale')
  }
};
</script>
<style scoped>
  .example-form-control{
    height: auto;
    min-height: 35px;
    border: 1px solid #eaeaea;
    padding: 0 10px;
    background-color: #fff;
    font-size: 13px;
    border-radius: 4;
    margin-left:2em;

  }


      .leloader,
.leloader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.leloader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #e74c3c;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

</style>
